import React from "react";
import { CircularProgress,  Alert } from "@mui/material";
import useContact from "../../appSettings/customhooks/contacthook.js";

const ContactDetails = () => {
  const { contact, loading, error } = useContact();

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  const contactInfo = {
    address: "",
    phone: "",
    email: "",
  };

  if (contact.length > 0) {
    contact.forEach(({ slug, value }) => {
      
      if (slug === "address") contactInfo.address = value;


      if (slug === "primary-phone-number") contactInfo.phone = value;

      if (slug === "primary-email") contactInfo.email = value;
    

   


    });
  }

  return contactInfo;
};

export default ContactDetails;
