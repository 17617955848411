import React, { useState } from "react";
import { Container, Typography, Box, Modal, Fade } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./testimonials.css"; // Import CSS file
import cloudyImage from "../../../images/cloudy.svg";
import useTestimonials from "../../../appSettings/customhooks/testimonialshook.js";
import { useNavigate } from "react-router-dom";
import { TESTIMONIAL, images } from "../../../appSettings/imageConstants.js";

const TestimonialsSection = () => {
  const navigate = useNavigate();
  const { testimonials, loading, error } = useTestimonials();

  // Ensure hooks are always called
  const [open, setOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleOpen = (video_url) => {
    setSelectedVideo(video_url);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedVideo(null);
    setOpen(false);
  };

  const getThumbnail = (video_url) => {
    try {
      const videoId = video_url.split("/").pop().split("?")[0];
      return `https://img.youtube.com/vi/${videoId}/0.jpg`;
    } catch {
      return ""; // Fallback thumbnail
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  // Return logic in the JSX
  return (
    <>
      {loading && <div>Loading services...</div>}
      {error && <div>{error}</div>}
      {!loading && !error && (
        <Box className="testimonials-section" component="section">
          <Container maxWidth="xl" className="postion-relative">
            <Grid container spacing={3}>
              <Grid item size={{ xs: 12, md: 4 }}>
                <Box color="white">
                  <Typography variant="h5">Testimonials</Typography>
                  <Typography variant="h2">
                    What People
                    <br />
                    Say About Us
                  </Typography>
                </Box>
              </Grid>
              <Grid item size={{ xs: 12, md: 8 }}>
                <Slider {...settings}>
                  {testimonials.map((testimonial, index) => (
                    <div key={index}>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item size={{ xs: 12, md: 6 }}>
                          <Box
                            sx={{ margin: { xs: "0 auto", sm: "0" }, p: 2 }}
                            onClick={
                              testimonial.video_url
                                ? () => handleOpen(testimonial.video_url)
                                : undefined
                            }
                            style={{
                              cursor: testimonial.video_url
                                ? "pointer"
                                : "default",
                            }}
                          >
                            <img
                              src={
                                testimonial.video_url
                                  ? getThumbnail(testimonial.video_url)
                                  : testimonial.image
                                  ? `${TESTIMONIAL}${testimonial.image}`
                                  : images.NoImage
                              }
                              alt={`Thumbnail for ${testimonial.name}`}
                              width="100%"
                              style={{ borderRadius: "8px" }}
                            />
                          </Box>
                        </Grid>
                        <Grid item size={{ xs: 12, md: 6 }} color="white">
                          <Typography
                            variant="body1"
                            dangerouslySetInnerHTML={{
                              __html: testimonial.description,
                            }}
                          />
                          <Typography variant="h6">
                            {testimonial.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                </Slider>
              </Grid>
            </Grid>
          </Container>
          <Box component="img" src={cloudyImage} className="divider" />
        </Box>
      )}

      {/* Video Modal */}
      <Modal open={open} onClose={handleClose} closeAfterTransition>
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              maxWidth: "800px",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 2,
              borderRadius: 2,
            }}
          >
            {selectedVideo && (
              <iframe
                width="100%"
                height="450"
                src={selectedVideo}
                title="Video Player"
                style={{ border: "none" }}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default TestimonialsSection;
