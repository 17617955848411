import React from 'react';
import Grid from "@mui/material/Grid2";
import useServices from "../../appSettings/customhooks/servicehook"; 
import {
    Typography,
    Link,
    List,
    ListItem,
} from "@mui/material";

export default function ServicesLinks() {
  const { services, loading, error } = useServices(); // Fetch services directly via the hook

  if (loading) return <div>Loading the services...</div>;
  if (error) return <div>{error}</div>;

    return (
        <Grid item size={{ xs: 12, md: 3 }}>
            <Typography variant="h6" className="footer-heading">
                Services
            </Typography>

            <List className="list-arrow half-list">
          
                {services.map((item) => (
                    <ListItem key={item.id}>
                        <Link
                            href={`/services/${item.slug}`}
                            underline="hover"
                            sx={{ color: "white" }}
                        >
                            {item.title}
                        </Link>
                    </ListItem>
                ))}
            </List>
        </Grid>
    );
}
