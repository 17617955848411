import React from "react";
import useMentors from "../../../appSettings/customhooks/mentorshook.js";
import { Card, CardContent, Typography, Avatar, Grid, CircularProgress, Stack,Alert,Button } from "@mui/material";
import InnerLayout from "../../../layouts/InnerLayout";

const Founder = () => {
  const { mentors, loading, error } = useMentors();

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  // Ensure mentors has data before accessing mentors_all
  if (!mentors || !mentors.mentors_all) {
    return <Typography variant="body1" align="center">No mentors available</Typography>;
  }

  return (
    <InnerLayout>
      <Typography variant="h4" sx={{ textAlign: "center" }} gutterBottom>
        Our Founder
      </Typography>
      <Grid container spacing={3}>
        {mentors.founders_all.map((mentorItem, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardContent>
                <Avatar
                  src={mentorItem.image}
                  alt={mentorItem.name}
                  sx={{ width: 80, height: 80, margin: "auto" }}
                />
                <Typography variant="h6" align="center" gutterBottom>
                  {mentorItem.name}
                </Typography>
                <Typography variant="body2" color="textSecondary" align="center">
                  {mentorItem.description}
                </Typography>
                <Stack alignItems={"center"}>
                  <Button sx={{ textAlign: "center" }}>Read More</Button>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </InnerLayout>
  );
};


export default Founder;
