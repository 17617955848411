import { useState, useEffect } from 'react';
import { getTestimonialsPage } from '../../appSettings/api/apiService';

const useTestimonialsPage = () => {
  const [testimonials, setTestimonials] = useState([]); // Initialize as an array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getTestimonialsPage();
        console.log('API Response:', data); // Debugging log
        // Adjust this line to match the API response structure
        setTestimonials(Array.isArray(data.testimonials_page_list) ? data.testimonials_page_list : []);
      } catch (err) {
        console.error('Error fetching testimonials:', err);
        setError('Failed to fetch testimonials details');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { testimonials, loading, error };
};

export default useTestimonialsPage;
