import React from "react";
import useMentors from "../../../appSettings/customhooks/mentorshook.js";
import {
  Card,
  Box,
  CardMedia,
  CardContent,
  Typography,
  Container,
  CircularProgress,
  Stack,
  Alert,
  Button,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import InnerLayout from "../../../layouts/InnerLayout.js";
import { useNavigate } from "react-router-dom";
import InnerBanner from "../../../components/innerBanner/innerBanner.js";
import InnerBannerImage from "../../../images/inner-banner.png";
import { IMAGE_TEAMS_URL, images } from "../../../appSettings/imageConstants.js";

const Mentor = () => {
  const { mentors, loading, error } = useMentors();
  const navigate = useNavigate();

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  // Ensure mentors has data before accessing mentors_all
  if (!mentors || !mentors.mentors_all) {
    return (
      <Typography variant="body1" align="center">
        No mentors available
      </Typography>
    );
  }

  return (
    <InnerLayout>
      <InnerBanner
        bannerImage={InnerBannerImage}
        title="Our Mentors"
        tagline=" "
      />
      <Box component="section">
      <Container maxWidth="xl">
          <Grid container spacing={3}>
            {mentors.mentors_all.map((mentorItem, index) => (
              <Grid item size={{xs:12, sm:6, md:4}}  key={index}>
                <Card className="mentorCard"
                  sx={{
                    height: '100%', // Ensures the card takes the full height of its container
                    display: 'flex', // Makes sure content is vertically aligned
                    flexDirection: 'column', // Ensures card content is stacked vertically
                    transition: '0.3s', // Smooth transition for hover effect
                  }}
                >
                  <CardContent sx={{ flex: 1 }}>
                    <CardMedia
                      component="img"
                      sx={{ height: 300, mb: 3 }}
                      src={
                        mentorItem.image
                          ? `${IMAGE_TEAMS_URL}${mentorItem.image}`
                          : images.NoImage
                      }
                      title={mentorItem.name || "Mentor Image"}
                    />
                    <Typography variant="h6" align="center" gutterBottom>
                      {mentorItem.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      align="center"
                      dangerouslySetInnerHTML={{
                        __html: mentorItem.description,
                      }}
                    />
                    <Stack alignItems={"center"}>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() =>
                          navigate(`/our-mentors/${mentorItem.slug}`)
                        }
                      >
                        Read More
                      </Button>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </InnerLayout>
  );
};

export default Mentor;
