import { useState, useEffect } from 'react';
import { getFooter } from '../api/apiService';

const useFooter = () => {
  const [footer, setFooter] = useState([]); // Initialize as an array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getFooter();
        console.log('API Response:', data); // Log for debugging
        // Ensure `data.contactdetails` is an array
        setFooter(Array.isArray(data.footer) ? data.footer : []);
      } catch (err) {
        console.error('Error fetching contact:', err);
        setError('Failed to fetch contact details');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { footer, loading, error };
};

export default useFooter;
