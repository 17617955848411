import { useState, useEffect } from "react";
import { getFaqinternship } from "../../../appSettings/api/apiService"; // Adjust path as needed

const useFaqinternship = () => {
  const [faq, setFaqinternship] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getFaqinternship();
        const faqData = data?.faqinternship_list || {};

        console.log("API Response:", data);  // Debugging: Log the full API response

        // Group FAQs by their category names
        const categorizedFaqs = {};
        Object.keys(faqData).forEach((categoryKey) => {
          categorizedFaqs[categoryKey] = faqData[categoryKey];
        });
        setFaqinternship(categorizedFaqs);
      } catch (err) {
        console.error("Error fetching FAQ:", err);
        setError("Failed to fetch FAQ");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);  // Empty dependency array to fetch data only once

  return { faq, loading, error };
};

export default useFaqinternship;
