import React from "react";
import { Box, Container, Typography, Card, CardContent, Button } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useNavigate } from 'react-router-dom';
import useServices from '../../../appSettings/customhooks/servicehook.js';
import { IMAGE_SERVICES_URL, images } from '../../../appSettings/imageConstants.js'; 
import './serviceStyle.css';

const ServicesGrid = () => {
  const navigate = useNavigate();
  const { services, loading, error } = useServices(); // Corrected variable name from 'service' to 'services'

  if (loading) return <div>Loading services...</div>;
  if (error) return <div>{error}</div>;

  // Display only the first 3 services
  const limitedServices = services.slice(0, 3);

  return (
    <Box backgroundColor="#E9F0FF" py={5}>
      <Container maxWidth="xl">
        <Typography variant="h4" component="h2" textAlign="center" mb={5}>
          Services
        </Typography>
        <Grid container spacing={3}>
          {limitedServices.map((item, index) => (
            <Grid item size={{ xs: 12, sm: 8, md: 4 }} key={index}>
              <Card 
                className="serviceCard homeserviceCard"
                align="center"
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(`/services/${item.slug}`)}
              >
                <CardContent>
                  <Box className="imageCircle">
                    <img
                      src={item.image ? `${IMAGE_SERVICES_URL}${item.image}` : images.NoImage}
                      alt={item.title}
                      className="serviceImage"
                    />
                  </Box>
                  <Typography variant="h6" gutterBottom>
                    {item.title}
                  </Typography>
                  {item.short_description && (
                    <Typography variant="body2" color="textSecondary" component="div">
                      <Box dangerouslySetInnerHTML={{ __html: item.short_description }} />
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Box textAlign="center" mt={3}>
          <Button variant="contained" onClick={() => navigate('/services')}>
            View More
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default ServicesGrid;
