import React, { useEffect, useState } from "react";
import InnerLayout from "../../layouts/InnerLayout";
import { Box, Container, Typography, Button, Card, List, ListItem } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import useEvents from "../../appSettings/customhooks/eventhook.js"; // Import useEvents hook directly
import { IMAGE_EVENTS_URL, images } from "../../appSettings/imageConstants.js";
import htmlConvert from "../../appSettings/htmlConvert.js";
import Grid from "@mui/material/Grid2";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RoomIcon from "@mui/icons-material/Room";
import WifiIcon from "@mui/icons-material/Wifi";

const formatDateTime = (dateTime) => {
  if (!dateTime) return null;

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  return new Intl.DateTimeFormat("en-US", options).format(new Date(dateTime));
};

function EventDetails() {
  const navigate = useNavigate();
  const { slug } = useParams();
  const { events } = useEvents(); // Use the hook directly
  const [event, setEvent] = useState(null);

  useEffect(() => {
    if (events && events.length > 0) {
      const selectedEvent = events.find((eventItem) => eventItem.slug === slug);
      setEvent(selectedEvent);
    }
  }, [events, slug]);

  if (!event) return <div>Loading event details...</div>;

  const eventDescription = event.long_description || ""; // Default to empty string if description is null/undefined

  return (
    <>
      <Helmet>
        <title>{event.name} - Event Details | MyWebsite</title>
        <meta name="description" content={event.short_description} />
        <meta name="keywords" content="" />
      </Helmet>
      <InnerLayout>
        <Box component="section" sx={{ padding: "2rem 0" }}>
          <Container maxWidth="xl">
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, md: 5 }}>
                <Card className="shadow-2" sx={{ marginRight: "30px" }}>
                  <Box
                    component="img"
                    sx={{ width: "100%", height: "auto", marginBottom: "1rem" }}
                    src={event.event_banner ? `${IMAGE_EVENTS_URL}${event.event_banner}` : images.NoImage}
                    alt={event.name}
                  />
                </Card>
              </Grid>
              <Grid size={{ xs: 12, md: 7 }}>
                <Typography className="text-primary" variant="h3" component="h1" gutterBottom>
                  {event.name}
                </Typography>
                <List className="custom-list">
                  {event.start_date_and_time && (
                    <ListItem className="mute-text">
                      <EventIcon fontSize="small" /> Start Date: {formatDateTime(event.start_date_and_time)}
                    </ListItem>
                  )}
                  {event.end_date_and_time && (
                    <ListItem className="mute-text">
                      <AccessTimeIcon fontSize="small" /> End Date: {formatDateTime(event.end_date_and_time)}
                    </ListItem>
                  )}
                  <ListItem className="mute-text">
                    <RoomIcon fontSize="small" /> Location: <Link to={event.Location}>{event.Location}</Link>
                  </ListItem>
                  <ListItem className="mute-text">
                    <WifiIcon fontSize="small" /> Mode: {event.type}
                  </ListItem>
                </List>
                {event.registration_button === 1 && (
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={() => navigate(`/register/${event.slug}`)}
                  >
                    Register
                  </Button>
                )}
              </Grid>
              <Grid size={{ xs: 12, md: 12 }}>
                <Typography variant="body2" color="textSecondary">
                  {htmlConvert({ htmlContent: eventDescription })}
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </InnerLayout>
    </>
  );
}

export default EventDetails;
