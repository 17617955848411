import React, { useState } from "react";
import {
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
  Button,
  Typography,
  Card,
  CardContent,
  Grid,
  Container,
} from "@mui/material";
import InnerLayout from "../../layouts/InnerLayout";

const EventRegistration = () => {
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    phone_no: "",
    occupation: "",
    are_you_attending: "",
    terms_and_conditions: false,
  });

  // Handle input change
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.terms_and_conditions) {
      alert("Please accept the terms and conditions to proceed.");
      return;
    }
    console.log("Submitted Form Data:", formData);
    // Submit form data to the backend
    // fetch("https://kalamdreamlabs.com/submit-registration", {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify(formData),
    // });
  };

  return (
    <InnerLayout>
      <Container maxWidth="xl" sx={{ marginY: 10 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Event Registration Form
        </Typography>
        <Grid container spacing={4}>
          {/* Form Section */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Enter Your Details
                </Typography>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        label="Full Name"
                        name="full_name"
                        value={formData.full_name}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        label="Email"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        label="Phone No"
                        name="phone_no"
                        value={formData.phone_no}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Occupation"
                        name="occupation"
                        value={formData.occupation}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl>
                        <FormLabel>Are You Attending?</FormLabel>
                        <RadioGroup
                          row
                          name="are_you_attending"
                          value={formData.are_you_attending}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="Yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="No"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <FormControlLabel
                    control={
                      <Checkbox
                        name="terms_and_conditions"
                        onChange={handleChange}
                        checked={formData.terms_and_conditions}
                      />
                    }
                    label="I Accept to all the Terms & Conditions."
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ marginTop: 2 }}
                  >
                    Register
                  </Button>
                </form>
              </CardContent>
            </Card>
          </Grid>

          {/* Image Section */}
          <Grid item xs={12} md={6}>
            <Card>
              <CardContent>
                <img
                  src="https://via.placeholder.com/600x400"
                  alt="Event Illustration"
                  style={{ width: "100%", borderRadius: "8px" }}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </InnerLayout>
  );
};

export default EventRegistration;
