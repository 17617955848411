import Home from "../../pages/home/home.js";
import Services from "../../pages/services/services.js";
import ServiceDetails from "../../pages/services/services-details.js";
import DynamicPage from "../../layouts/dynamicPage.js";
import Clubs from "../../pages/clubs/clubs.js";
import Events from "../../pages/events/events.js";
import EventsDetails from "../../pages/events/events-details.js";
import Contact from "../../pages/contact/contact.js";
import Thankyou from "../../pages/contact/thankyou.js";
import EventRegistration from "../../pages/events/event_registration.js";
import LoginSignup from "../../pages/login_signup/login_signup.js";
import ClubRegistration from "../../pages/clubs/clubRegistration.js";
import EditProfile from "../../pages/profile/editprofile.js";
import ViewProfile from "../../pages/profile/viewprofile.js";
import Mentor from "../../pages/about/mentors/mentors.js";
import Founder from "../../pages/about/founder/founder.js";
import Journey from "../../pages/about/journey/journey.js";
import InternshipForm from "../../pages/internship-form/internshipform.js";
import SuccessPage from "../../components/successpage/success.js";
import MentorsDetails from "../../pages/about/mentors/mentors-details.js";
import ClubsDetails from "../../pages/clubs/clubs-details.js";
import Testimonials from "../../pages/testimonials/testimonials.js";
import Choosephoto from "../../pages/login_signup/choosephoto.js";

// Define all the routes
const routes = [
  { path: "/", element: <Home /> },
  { path: "/contact", element: <Contact /> },
  { path: "/thank-you", element: <Thankyou /> },
  { path: "/clubs", element: <Clubs /> },
  { path: "/clubs/:slug", element: <ClubsDetails /> },
  { path: "/testimonialspage", element: <Testimonials /> },
  { path: "/services", element: <Services /> },
  { path: "/services/:slug", element: <ServiceDetails /> },
  { path: "/events", element: <Events /> },
  { path: "/events/:slug", element: <EventsDetails /> },
  { path: "/event-registration", element: <EventRegistration /> },
  { path: "/edit-profile", element: <EditProfile /> },
  { path: "/view-profile", element: <ViewProfile /> },
  { path: "/user-login", element: <LoginSignup /> },
  { path: "/choosephoto", element: <Choosephoto /> },
  { path: "/internship-registration", element: <InternshipForm /> },
  { path: "/club-registration", element: <ClubRegistration /> },
  { path: "/registration-success", element: <SuccessPage /> },
  { path: "/our-mentors", element: <Mentor /> },
  { path: "/our-mentors/:slug", element: <MentorsDetails /> },
  { path: "/founder", element: <Founder /> },

  { path: "/:slug", element: <DynamicPage /> },
];

export default routes;
