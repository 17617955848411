import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Container } from "@mui/material";
import InnerBanner from "../../components/innerBanner/innerBanner.js";
import InnerLayout from "../../layouts/InnerLayout.js";
import htmlConvert from "../../appSettings/htmlConvert.js";
import InnerBannerImage from "../../images/inner-banner.png";
import useServices from "../../appSettings/customhooks/servicehook.js"; // Custom hook



import useFaqinternship from "../services/accordion/accordionHook.js";
import ServiceAccordion from "../services/accordion/serviceAccordion.js";

function ServiceDetails() {
  const { slug } = useParams();
  const { services, loading: servicesLoading, error: servicesError } = useServices();
  const { faq, loading: faqLoading, error: faqError } = useFaqinternship();
  const [expanded, setExpanded] = useState(false);

  if (servicesLoading) return <div>Loading service details...</div>;
  if (servicesError) return <div>{servicesError}</div>;

  const ourService = services.find((item) => item.slug === slug);
  if (!ourService) return <div>Service not found.</div>;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <InnerLayout>
      <InnerBanner
        bannerImage={InnerBannerImage}
        title={ourService.title}
        tagline={ourService.short_description || "Service Details"}
      />
      <Box component="section" py={4}>
        <Container maxWidth="xl">
          {htmlConvert({ htmlContent: ourService.description })}
        </Container>
      </Box>

      {/* FAQ Section */}
      <ServiceAccordion
        faq={faq}
        ourService={ourService}
        expanded={expanded}
        handleChange={handleChange}
        faqLoading={faqLoading}
        faqError={faqError}
      />
    </InnerLayout>
  );
}

export default ServiceDetails;
