import { useState, useEffect } from 'react';
import { getTemplatesettings } from '../../appSettings/api/apiService';

const useTemplatesettings = () => {
  const [templatesettings, setTemplatesettings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getTemplatesettings();
        setTemplatesettings(data.templatesetting || []);
  
        // Apply all settings as CSS variables
        data.templatesetting.forEach(({ slug, value }) => {
          document.documentElement.style.setProperty(`--${slug}`, value);
        });
  
        // Set the theme attribute based on dark-theme
        const darkThemeSetting = data.templatesetting.find(({ slug }) => slug === 'dark-theme');
        document.documentElement.setAttribute('data-theme', darkThemeSetting?.value === "1" ? 'dark' : 'light');
      } catch (err) {
        console.error('Error fetching template settings:', err);
        setError('Failed to fetch template settings');
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);

  return { templatesettings, loading, error };
};

export default useTemplatesettings;
