import React from "react";
import { Box, Container, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function ServiceAccordion({ faq, ourService, expanded, handleChange, faqLoading, faqError }) {
  return (
    <Box component="section" py={4}>
      <Container maxWidth="xl">
        {/* Conditionally render the heading only if there are matching FAQs */}
        {Object.keys(faq || {}).some(
          (categoryKey) =>
            ourService.title ===
            categoryKey.charAt(0).toUpperCase() + categoryKey.slice(1)
        ) && (
          <Typography variant="h4" gutterBottom>
            Frequently Asked Questions
          </Typography>
        )}

        {faqLoading && <Typography>Loading FAQs...</Typography>}
        {faqError && <Typography>{faqError}</Typography>}

        {Object.keys(faq || {})
          .filter(
            (categoryKey) =>
              ourService.title ===
              categoryKey.charAt(0).toUpperCase() + categoryKey.slice(1)
          )
          .map((categoryKey) => (
            <Box key={categoryKey} marginBottom={4}>
              {faq[categoryKey].map((faqItem, index) => (
                <Accordion
                  key={faqItem.id}
                  expanded={expanded === `panel${categoryKey}${index}`}
                  onChange={handleChange(`panel${categoryKey}${index}`)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${categoryKey}${index}-content`}
                    id={`panel${categoryKey}${index}-header`}
                  >
                    <Typography>{faqItem.question}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography
                      dangerouslySetInnerHTML={{ __html: faqItem.answer }}
                    />
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          ))}
      </Container>
    </Box>
  );
}

export default ServiceAccordion;
