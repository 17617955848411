import { useState, useEffect } from 'react';
import { getServices } from '../api/apiService'; // API call for fetching services

const useServices = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const data = await getServices();
        setServices(data || []); // Default to empty array if data is falsy
      } catch (err) {
        setError('Failed to fetch services');
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, []); // Fetch data only once

  return { services, loading, error };
};

export default useServices;
