import React from "react";
import { Navigate } from "react-router-dom";
import InnerLayout from "../../layouts/InnerLayout";
import { Box, Container } from "@mui/system";

function Choosephoto() {
  const isAuthenticated = true; // Replace this with actual authentication logic

  if (!isAuthenticated) {
    return <Navigate to="/" />; // Redirect to login page if not authenticated
  }

  return (
    <InnerLayout>
        <Box component='section'>
        <Container maxWidth="xs">
      <div>choosephoto</div>
      </Container>
      </Box>
    </InnerLayout>
  );
}

export default Choosephoto;
