import React from "react";
import {
  Container,
  Typography,
  Box,
  CardMedia,
  Button,
} from "@mui/material";
import useAboutKdlHooks from "../../../../appSettings/customhooks/aboutkdlhook";
import { SITE_IMAGES_URL } from "../../../../appSettings/imageConstants.js";

const AboutKdl = () => {
  const { homepageblock, loading, error } = useAboutKdlHooks();

  // Extract the `welcome` category
  const welcomeData = homepageblock.welcome || [];
  const image = welcomeData.find((item) => item.slug === "welcome-block-image")?.value;
  const tagline = welcomeData.find((item) => item.slug === "welcome-block-tagline")?.value;
  const title = welcomeData.find((item) => item.slug === "welcome-block-title")?.value;
  const description = welcomeData.find((item) => item.slug === "welcome-block-description")?.value;
  const buttonText = welcomeData.find((item) => item.slug === "welcome-block-button-text")?.value;
  const buttonUrl = welcomeData.find((item) => item.slug === "welcome-block-button-url")?.value;

  if (loading) return <Box>Loading...</Box>;
  if (error) return <Box>Error: {error}</Box>;

  return (
    <Container sx={{ display: "flex", alignItems: "center", py: 4 }}>
      {/* Image Section */}
      {image && (
        <Box sx={{ flex: "1", textAlign: "center" }}>
          <CardMedia
            component="img"
            src={`${SITE_IMAGES_URL}${image}`} 
      
            alt="Welcome Image"
            sx={{ borderRadius: "8px", boxShadow: 3, width: "100%", height: "auto" }}
          />
        </Box>
      )}

      {/* Content Section */}
      <Box
        sx={{
          flex: "2",
          textAlign: "left",
          paddingLeft: 4,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h2" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1" paragraph>
          {description && (<Box component="div" dangerouslySetInnerHTML={{ __html: description }} />)}
        </Typography>
        {buttonText && buttonUrl && (
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              href={buttonUrl}
              target="_blank"
              rel="noopener"
            >
              {buttonText}
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default AboutKdl;
