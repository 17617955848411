import React from 'react';
import InnerLayout from '../../layouts/InnerLayout';
import {
  Box,
  Container,

  Typography,

} from "@mui/material";

function Thankyou() {
  return (
    <InnerLayout>
    
      <Box component="section">
        <Container align="center">
          <Typography variant="h4" gutterBottom>
            Thank you for contacting us!
          </Typography>
          <Typography variant="body1">
            We will get back to you as soon as possible.
          </Typography>
        </Container>
      </Box>
    </InnerLayout>
  );
}

export default Thankyou;
