import React from 'react';

import InnerLayout from "../../layouts/InnerLayout.js";
import InnerBanner from "../../components/innerBanner/innerBanner.js";
import { Card, CardContent, Typography, Box, Container } from "@mui/material";
import Grid from "@mui/material/Grid2";
import InnerBannerImage from '../../images/inner-banner.png';
import useTestimonialsPage from '../testimonials/testimonialsHook.js';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import Avatar from '@mui/material/Avatar';
import Rating from '@mui/material/Rating';


function Testimonials() {
  const { testimonials, loading, error } = useTestimonialsPage();

  return (
    <InnerLayout>
      <InnerBanner bannerImage={InnerBannerImage} title="Testimonials" tagline="" />
      <Box component="section">
        <Container maxWidth="xl">
          <Grid container spacing={5}>
            
          {testimonials.map((item, index) => (
              <Grid item xs={12} sm={6} md={4}>
                <Card 
                  className="testimonialCard" 
                  align="center" 
                  sx={{
                    width: '25%',
                    borderRadius: 3,
                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
                    padding: 2,
                  }}
                >
                  <CardContent>
                    {/* Quote Icon */}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: 2,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: '2rem',
                          color: 'rgba(255, 255, 255, 0.7)',
                        }}
                      >
                        <FormatQuoteIcon />
                      </Typography>
                    </Box>

                    {/* Description */}
                    <Typography
                      variant="body2"
                      color="rgba(255, 255, 255, 0.7)"
                      gutterBottom
                      sx={{ marginBottom: 2 }}
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />

                    {/* Rating */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 3 }}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: 600,
                        }}
                      >
                        <Rating name="read-only" value={item.rating} readOnly />
                      </Typography>
                    </Box>
                    <hr></hr>

                    {/* Profile Section */}
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        justifyContent: 'center',
                      }}
                    >
                      <Avatar alt="Remy Sharp" src="{item.image}" />
                      <Box>
                        <Typography variant="h6" gutterBottom>
                          {item.name}
                        </Typography>
                        <Typography variant="body1" color="rgba(255, 255, 255, 0.6)">
                          {item.designation}
                          {item.company}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
              </Card>

              </Grid>
          ))}
          
          </Grid>
        </Container>
      </Box>
    </InnerLayout>
  );
}

export default Testimonials;


