import { useState, useEffect } from 'react';
import { getTestimonials } from '../api/apiService.js';

const useTestimonials = () => {
  const [testimonials, setTestimonials] = useState([]); // Initialize as an array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getTestimonials();
        console.log('API Response:', data); // Debugging log
        // Adjust this line to match the API response structure
        setTestimonials(Array.isArray(data.testimonials_list) ? data.testimonials_list : []);
      } catch (err) {
        console.error('Error fetching testimonials:', err);
        setError('Failed to fetch testimonials details');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { testimonials, loading, error };
};

export default useTestimonials;
