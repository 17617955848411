import React from 'react';
import { useNavigate } from 'react-router-dom';
import useClubs from '../../appSettings/customhooks/clubhook.js'; // Import the custom hook
import "../services/services.css"; // Ensure this contains your styling
import InnerLayout from "../../layouts/InnerLayout.js";
import InnerBanner from "../../components/innerBanner/innerBanner.js";
import { Card, CardContent, Typography, Box, Container } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { IMAGE_SERVICES_URL, images } from '../../appSettings/imageConstants.js'; // Import images here
import InnerBannerImage from '../../images/inner-banner.png';
import './club.css';

function Clubs() {
  const navigate = useNavigate();
  const { clubs, loading, error } = useClubs(); // Use the custom hook directly

  if (loading) return <div>Loading the clubs...</div>;
  if (error) return <div>{error}</div>;

  return (
    <InnerLayout>
      <InnerBanner bannerImage={InnerBannerImage} title="Clubs" tagline="" />
      <Box component="section">
        <Container maxWidth="xl">
          <Grid container spacing={5}>
            {clubs.map((item, index) => (
              <Grid item size={{ xs: 12, sm: 6, md: 4 }} key={index}>
                <Card
                  className="clubCard" // Apply the same CSS class for hover effect
                  align="center"
                  sx={{ height: '100%', cursor: 'pointer' }}
                  onClick={() => navigate(`/clubs/${item.slug}`)} // Use slug for navigation
                >
                  <CardContent>
                    <Box
                      component="img"
                      src={item.image ? `${IMAGE_SERVICES_URL}${item.image}` : images.NoImage}
                      alt={item.title}
                      className="w-100"
                      sx={{ mb: 2 }}
                    />
                    <Typography gutterBottom variant="h5" component="div">
                      {item.title}
                    </Typography>
                    {item?.short_description && (
                      <Box
                        component="div"
                        variant="body2"
                        color="text.secondary"
                        dangerouslySetInnerHTML={{ __html: item.short_description }}
                      />
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </InnerLayout>
  );
}

export default Clubs;
