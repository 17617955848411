import React, { useState } from "react";
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  TextareaAutosize,
  List,
  ListItem,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { LocationOn, Phone, Email } from "@mui/icons-material";
import InnerLayout from "../../layouts/InnerLayout";
import InnerBanner from "../../components/innerBanner/innerBanner";
import InnerBannerImage from "../../images/inner-banner.png";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ContactDetails from "../../components/contact/contact-details";
import useTemplatesettings from "../../appSettings/customhooks/templateSettingshook"; // Import the default hook
import { TEXTPRIMARY } from "../../components/templateSettings/color"; // Import the primary color constant

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const navigate = useNavigate();
  const { templatesettings, loading, error } = useTemplatesettings();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("https://test.kalamlabs.com/send-email", formData);
      console.log("Email sent:", response.data.message);
      navigate("/thank-you");
    } catch (error) {
      console.error("Failed to send email:", error);
    }
  };

  const { address, phone, email } = ContactDetails();

  const contactItems = [
    {
      label: "Address",
      value: address,
      icon: <LocationOn sx={{ mr: 1 }} />,
      href: `https://maps.app.goo.gl/${address}`,
    },
    {
      label: "Phone",
      value: phone,
      icon: <Phone sx={{ mr: 1 }} />,
      href: `tel:${phone}`,
    },
    {
      label: "Email",
      value: email,
      icon: <Email sx={{ mr: 1 }} />,
      href: `mailto:${email}`,
    },
  ];

  const primaryColor = templatesettings?.find(setting => setting.slug === TEXTPRIMARY)?.value || "#000000";

  return (
    <InnerLayout>
      <InnerBanner bannerImage={InnerBannerImage} title="Contact" />
      <Box component="section">
        <Container maxWidth="xl">
          <Grid container spacing={4}>
            {/* Left Section */}
            <Grid item size={{xs:12, md:6 }}>
              <Typography variant="h4" gutterBottom className="text-primary">
                Get In Touch
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item size={{xs:12, sm:6 }}>
                    <TextField
                    className="form-feild"
                      fullWidth
                      required
                      name="name"
                      label="Name"
                      value={formData.name}
                      onChange={handleChange}
                      inputProps={{ maxLength: 50 }}
                    />
                  </Grid>
                  <Grid item size={{xs:12, sm:6 }}>
                    <TextField
                      fullWidth
                      required
                      name="email"
                      label="Email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item size={{xs:12 }}>
                    <TextField
                      fullWidth
                      required
                      name="phone"
                      label="Phone"
                      type="text"
                      inputProps={{ maxLength: 10, minLength: 10 }}
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item size={{xs:12 }}>
                    <TextareaAutosize
                      minRows={5}
                      placeholder="Message (min. 50 characters)"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      style={{ width: "100%", padding: "10px" }}
                      required
                    />
                  </Grid>
                </Grid>
                <Box mt={2}>
                  <Button type="submit" variant="contained" color="primary">
                    Submit
                  </Button>
                </Box>
              </form>
            </Grid>

            {/* Right Section */}
            <Grid item size={{xs:12, md:6 }}>
              <Typography variant="h4" gutterBottom className="text-primary">
                Contact
              </Typography>
              <List>
                {contactItems.map((item, index) =>
                  item.value ? (
                    <ListItem key={index}>
                      <Link href={item.href} target="_blank">
                        {item.icon}
                        {item.value}
                      </Link>
                    </ListItem>
                  ) : null
                )}
              </List>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </InnerLayout>
  );
};

export default ContactUs;
