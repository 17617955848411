import React, { useState } from "react";
import {
  Box,
  Drawer,
  Button,
  Typography,
  IconButton,
  Container,
  Card,
  Avatar
} from "@mui/material";

import Grid from "@mui/material/Grid2";
import CloseIcon from "@mui/icons-material/Close";

import InnerLayout from "../../layouts/InnerLayout";
import EventCard from "../../components/customeCards/cards";
import MenuIcon from "@mui/icons-material/Menu";

import "./editprofile.css";

const ViewProfile = () => {
  const [currentStep, setCurrentStep] = useState(1);
 

  
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleNext = () => {
    if (currentStep < 6) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleStepClick = (step) => {
    setCurrentStep(step);
  };

  const user = {
    personalInfo: {
      firstName: "John",
      lastName: "Doe",
      email: "john.doe@example.com",
      phone: "9876543210",
      dateOfBirth: "1995-08-15",
      gender: "Male",
      address: {
        country: "India",
        state: "Andhra Pradesh",
        district: "Guntur",
        city: "Vijayawada",
        houseNumber: "45/A",
        zipCode: "520001",
      },
      hobbies: ["Reading", "Traveling"],
      interests: ["Web Development", "AI/ML"],
      bloodGroup: "O+",
    },
    educationalDetails: {
      program: "B.Tech",
      university: "Vignan's Institute of Information Technology",
      branch: "Computer Science",
      recentCGPA: 8.5,
      startingYear: 2014,
      lastYear: 2018,
      academicAchievements: ["Topper of the Batch", "Published Research Paper"],
      extraCurricularAchievements: ["Won Hackathon", "Organized Coding Fest"],
    },
    skillsAndExperience: {
      technicalSkills: ["JavaScript", "React", "Node.js", "MongoDB"],
      softSkills: ["Teamwork", "Communication", "Problem-Solving"],
      internshipDetails: {
        companyName: "Tech Solutions Pvt. Ltd.",
        domain: "Web Development",
        duration: "6 months",
        year: 2017,
      },
      project: {
        title: "E-commerce Website",
        githubLink: "https://github.com/johndoe/ecommerce",
      },
    },
    additionalDetails: {
      resumeLink: "https://drive.google.com/file/d/resume-link",
      linkedin: "https://linkedin.com/in/johndoe",
      github: "https://github.com/johndoe",
      personalWebsite: "https://johndoe.dev",
      portfolio: "https://johndoeportfolio.com",
    },
  };

  return (
    <InnerLayout>
      <Box component="section">
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item size={{ xs: 12, md: 3, sm: 3 }}>
              {/* Sidebar */}
              <IconButton
                onClick={toggleMenu}
                sx={{
                  display: { xs: "block", sm: "none" },
                  position: "absolute", // Position the button at the top left
                  top: 64,
                  left: 16,
                  zIndex: 10, // Ensure it's above other content
                }}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="left"
                open={isMenuOpen}
                onClose={toggleMenu}
                sx={{
                  display: { xs: "block", sm: "none" },
                }}
              >
                <Box
                  sx={{
                    width: 200,
                    padding: 2,
                  }}
                >
                  <Button onClick={toggleMenu}>
                    <CloseIcon />
                  </Button>
                  <Card>
                    {/* Image Upload */}
                    <Box
                      mb={2}
                      display="flex" // Use flexbox
                      justifyContent="center" // Horizontally center content
                      alignItems="center" // Vertically center content (optional, ensures centering in both axes)
                      m={2}
                    >
                      <Avatar
                        alt="Remy Sharp"
                        src="https://i.pinimg.com/originals/57/96/7b/57967b8930c1ce7f5269370bb3faea67.jpg"
                        sx={{ width: 56, height: 56 }}
                      />
                    </Box>

                    <Typography variant="body1" textAlign="center">
                      John Doe
                    </Typography>
                    <Typography variant="body2" textAlign="center">
                      john.doe@example.com
                    </Typography>

                    <Box className="edit-menu" onClick={toggleMenu}>
                      {[
                        "Personal Information",
                        "Educational Information",

                        "Skills And Experience",
                        "Additional Information",
                        "Internship Details",
                        "Upcoming Events",
                      ].map((title, index) => (
                        <Box
                          key={index}
                          className={`profile-list ${
                            currentStep === index + 1 ? "active" : ""
                          }`}
                          display="flex"
                          alignItems="center"
                          mb={2}
                          sx={{
                            cursor: "pointer",
                            color:
                              currentStep === index + 1
                                ? "primary.main"
                                : "text.secondary",
                          }}
                          onClick={() => handleStepClick(index + 1)}
                        >
                          <Typography variant="body1">{title}</Typography>
                        </Box>
                      ))}
                    </Box>
                  </Card>
                </Box>
              </Drawer>
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                {/* Sidebar content for larger screens */}
                <Card>
                  {/* Image Upload */}
                  <Box
                    mb={2}
                    display="flex" // Use flexbox
                    justifyContent="center" // Horizontally center content
                    alignItems="center" // Vertically center content (optional, ensures centering in both axes)
                    m={2}
                  >
                    <Avatar
                      alt="Remy Sharp"
                      src="https://i.pinimg.com/originals/57/96/7b/57967b8930c1ce7f5269370bb3faea67.jpg"
                      sx={{ width: 56, height: 56 }}
                    />
                  </Box>

                  <Typography variant="body1" textAlign="center">
                    John Doe
                  </Typography>
                  <Typography variant="body2" textAlign="center">
                    john.doe@example.com
                  </Typography>

                  <Box className="edit-menu">
                    {[
                      "Personal Information",
                      "Educational Information",

                      "Skills And Experience",
                      "Additional Information",
                      "Internship Details",
                      "Upcoming Events",
                    ].map((title, index) => (
                      <Box
                        key={index}
                        className={`profile-list ${
                          currentStep === index + 1 ? "active" : ""
                        }`}
                        display="flex"
                        alignItems="center"
                        mb={2}
                        sx={{
                          cursor: "pointer",
                          color:
                            currentStep === index + 1
                              ? "primary.main"
                              : "text.secondary",
                        }}
                        onClick={() => handleStepClick(index + 1)}
                      >
                        <Typography variant="body1">{title}</Typography>
                      </Box>
                    ))}
                  </Box>
                </Card>
              </Box>
            </Grid>

            <Grid item size={{ xs: 12, md: 9, sm: 9 }}>
              {/* Form */}
              <form id="wizard">
                <Box display="flex" flexDirection="column" gap={1}>
                  {/* Step 1: Personal Information */}
                  {currentStep === 1 && (
                    <Box
                      className="wizard-step"
                      sx={{
                        p: 3,
                        borderRadius: 2,
                        boxShadow: 2,
                        minHeight: "60vh",
                      }}
                    >
                      <h1>Personal Information</h1>
                      <p>
                        <strong>Name:</strong> {user.personalInfo.firstName}{" "}
                        {user.personalInfo.lastName}
                      </p>
                      <p>
                        <strong>Email:</strong> {user.personalInfo.email}
                      </p>
                      <p>
                        <strong>Phone:</strong> {user.personalInfo.phone}
                      </p>
                      <p>
                        <strong>Date of Birth:</strong>{" "}
                        {user.personalInfo.dateOfBirth}
                      </p>
                      <p>
                        <strong>Gender:</strong> {user.personalInfo.gender}
                      </p>
                      <p>
                        <strong>Address:</strong>{" "}
                        {`${user.personalInfo.address.houseNumber}, ${user.personalInfo.address.city}, ${user.personalInfo.address.state}, ${user.personalInfo.address.country}, ${user.personalInfo.address.zipCode}`}
                      </p>
                      <p>
                        <strong>Hobbies:</strong>{" "}
                        {user.personalInfo.hobbies.join(", ")}
                      </p>
                      <p>
                        <strong>Interests:</strong>{" "}
                        {user.personalInfo.interests.join(", ")}
                      </p>
                      <p>
                        <strong>Blood Group:</strong>{" "}
                        {user.personalInfo.bloodGroup}
                      </p>
                    </Box>
                  )}
                  {/* Step 2: Educational Details */}
                  {currentStep === 2 && (
                    <Box
                      className="wizard-step"
                      sx={{
                        p: 3,
                        borderRadius: 2,
                        boxShadow: 2,
                        minHeight: "60vh",
                      }}
                    >
                      <h2>Educational Details</h2>
                      <p>
                        <strong>Program:</strong>{" "}
                        {user.educationalDetails.program}
                      </p>
                      <p>
                        <strong>University:</strong>{" "}
                        {user.educationalDetails.university}
                      </p>
                      <p>
                        <strong>Branch:</strong>{" "}
                        {user.educationalDetails.branch}
                      </p>
                      <p>
                        <strong>Recent CGPA:</strong>{" "}
                        {user.educationalDetails.recentCGPA}
                      </p>
                      <p>
                        <strong>Academic Achievements:</strong>{" "}
                        {user.educationalDetails.academicAchievements.join(
                          ", "
                        )}
                      </p>
                      <p>
                        <strong>Extra-Curricular Achievements:</strong>{" "}
                        {user.educationalDetails.extraCurricularAchievements.join(
                          ", "
                        )}
                      </p>
                    </Box>
                  )}

                  {/* Step 3: Skills and Experience */}
                  {currentStep === 3 && (
                    <Box
                      className="wizard-step"
                      sx={{
                        p: 3,
                        borderRadius: 2,
                        boxShadow: 2,
                        minHeight: "60vh",
                      }}
                    >
                      <h2>Skills and Experience</h2>
                      <p>
                        <strong>Technical Skills:</strong>{" "}
                        {user.skillsAndExperience.technicalSkills.join(", ")}
                      </p>
                      <p>
                        <strong>Soft Skills:</strong>{" "}
                        {user.skillsAndExperience.softSkills.join(", ")}
                      </p>
                      <p>
                        <strong>Internship:</strong>{" "}
                        {user.skillsAndExperience.internshipDetails.domain} at{" "}
                        {user.skillsAndExperience.internshipDetails.companyName}
                      </p>
                      <p>
                        <strong>Project Title:</strong>{" "}
                        {user.skillsAndExperience.project.title}
                      </p>
                      <p>
                        <strong>GitHub Link:</strong>{" "}
                        <a href={user.skillsAndExperience.project.githubLink}>
                          {user.skillsAndExperience.project.githubLink}
                        </a>
                      </p>
                    </Box>
                  )}

                  {/* Step 4: Additional Details */}
                  {currentStep === 4 && (
                    <Box
                      className="wizard-step"
                      sx={{
                        p: 3,
                        borderRadius: 2,
                        boxShadow: 2,
                        minHeight: "60vh",
                      }}
                    >
                      <h2>Additional Details</h2>
                      <p>
                        <strong>Resume:</strong>{" "}
                        <a href={user.additionalDetails.resumeLink}>Download</a>
                      </p>
                      <p>
                        <strong>LinkedIn:</strong>{" "}
                        <a href={user.additionalDetails.linkedin}>
                          {user.additionalDetails.linkedin}
                        </a>
                      </p>
                      <p>
                        <strong>GitHub:</strong>{" "}
                        <a href={user.additionalDetails.github}>
                          {user.additionalDetails.github}
                        </a>
                      </p>
                      <p>
                        <strong>Portfolio:</strong>{" "}
                        <a href={user.additionalDetails.portfolio}>
                          {user.additionalDetails.portfolio}
                        </a>
                      </p>
                    </Box>
                  )}
                  {/* Step 5: Internship Details */}
                  {currentStep === 5 && (
                    <Box
                      className="wizard-step"
                      sx={{
                        p: 3,
                        borderRadius: 2,
                        boxShadow: 2,
                        minHeight: "60vh",
                      }}
                    >
                      <Typography
                        variant="h6"
                        textAlign="center"
                        fullWidth
                        margin="normal"
                      >
                        Internship Details
                      </Typography>

                      {/* Present progress and activities */}
                    </Box>
                  )}
                  {currentStep === 6 && (
                    <Box
                      className="wizard-step"
                      sx={{
                        borderRadius: 2,
                        boxShadow: 2,
                        minHeight: "60vh",
                        minWidth: "80vw",
                        padding: 2,
                      }}
                    >
                      <Typography
                        variant="h6"
                        textAlign="center"
                        mb={2}
                        padding={1}
                      >
                        Upcoming Events
                      </Typography>

                      <Box
                        sx={{
                          maxHeight: "60vh",
                          display: "flex",
                          flexDirection: {
                            xs: "column", // Vertical scroll for mobile
                            sm: "row", // Horizontal scroll for larger screens
                          },
                          overflowY: {
                            xs: "auto", // Enable vertical scrolling for mobile
                            sm: "hidden", // No vertical scroll for larger screens
                          },
                          overflowX: {
                            xs: "hidden", // No horizontal scroll for mobile
                            sm: "auto", // Enable horizontal scrolling for larger screens
                          },
                          padding: "16px",
                          scrollbarWidth: "thin",
                          "&::-webkit-scrollbar": {
                            width: "8px",
                            height: "8px", // Ensure horizontal scroll also has a visible scrollbar
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "rgba(0, 0, 0, 0.2)",
                            borderRadius: "4px",
                          },
                        }}
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="center"
                          gap={1}
                          sx={{
                            flexWrap: { xs: "wrap", sm: "nowrap" }, // Wrap items for mobile
                          }}
                        >
                          <EventCard />
                          <EventCard />
                          <EventCard />
                          <EventCard />
                          <EventCard />
                          <EventCard />
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {/* Navigation Buttons */}
                  <Box display="flex" justifyContent="space-between" mt={2}>
                    {currentStep > 1 && (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleBack}
                      >
                        Back
                      </Button>
                    )}
                    {currentStep < 5 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        Next
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="success"
                        // onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    )}
                  </Box>
                </Box>
              </form>
            </Grid>
            {/* <Grid size={3}></Grid> */}
          </Grid>
        </Container>
      </Box>
    </InnerLayout>
  );
};

export default ViewProfile;
