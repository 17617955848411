// hooks/useHeader.js
import { useState, useEffect } from 'react';
import { getSlider } from '../api/apiService'; // Adjust path as needed

const useSlider = () => {
  const [slider, setSlider] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getSlider();
        setSlider(data || []); // Default to empty array if data is falsy
      } catch (err) {
        console.error('Error fetching slider:', err); // Improved logging
        setError('Failed to fetch slider'); // Store readable error message
      } finally {
        setLoading(false); // Ensure loading state is handled correctly
      }
    };

    fetchData();
  }, []);

  return { slider, loading, error };
};

export default useSlider;
