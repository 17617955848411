import React from "react";
import { useParams } from "react-router-dom";
import useClubs from "../../appSettings/customhooks/clubhook.js"; // Use the custom hook to fetch data
import InnerBanner from "../../components/innerBanner/innerBanner.js";
import InnerLayout from "../../layouts/InnerLayout.js";
import { Box, Container } from "@mui/material";
import htmlConvert from "../../appSettings/htmlConvert.js"; // Import htmlConvert
import InnerBannerImage from "../../images/inner-banner.png";

function ClubsDetails() {
  const { slug } = useParams();
  const { clubs, loading, error } = useClubs(); // Fetch clubs using the hook
  const ourClub = clubs.find((item) => item.slug === slug); // Find the specific club based on the slug

  if (loading) return <div>Loading club details...</div>;
  if (error) return <div>{error}</div>;
  if (!ourClub) return <div>Club not found.</div>;

  return (
    <InnerLayout>
      <InnerBanner
        bannerImage={InnerBannerImage}
        title={ourClub.title} // Dynamically fetch the title from the club
        tagline=""
      />
      <Box component="section" py={4}>
        <Container maxWidth="xl">
      
          {/* Use htmlConvert component to display the club description */}
          {htmlConvert({ htmlContent: ourClub.description })}
        </Container>
      </Box>
    </InnerLayout>
  );
}

export default ClubsDetails;
