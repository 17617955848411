import React, { useState } from "react";
import { Container, Box, TextField, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import InnerLayout from "../../layouts/InnerLayout";

function LoginSignup({ onLogin }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  // Static credentials
  const validUsername = "kalamdreamlabs";
  const validPassword = "Kalam@123456";

  const handleLogin = (e) => {
    e.preventDefault();
    // Validate credentials
    if (username === validUsername && password === validPassword) {
      onLogin(); // Set authenticated state
      navigate("/choosephoto"); // Redirect to Choosephoto
    } else {
      setError("Invalid username or password");
    }
  };

  return (
    <InnerLayout>
    <Box component="section">
      <Container maxWidth="xs">
        <Box component="form" onSubmit={handleLogin} sx={{ mt: 4 }}>
          <Typography variant="h4" gutterBottom>
            Login
          </Typography>
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          {error && (
            <Typography color="error" sx={{ mt: 1 }}>
              {error}
            </Typography>
          )}
          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
            Login
          </Button>
        </Box>
      </Container>
    </Box>
    </InnerLayout>
  );
}

export default LoginSignup;
