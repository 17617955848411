import { useState, useEffect } from 'react';
import { getThemesettings } from '../../appSettings/api/apiService'; // Corrected import

const useThemeSettings = () => {
  const [themesettings, setThemeSettings] = useState([]); // Initialize as an array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getThemesettings(); // Correct function call
        setThemeSettings(Array.isArray(data.themesetting) ? data.themesetting : []);
      } catch (err) {
        console.error('Error fetching theme settings:', err);
        setError('Failed to fetch theme settings');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { themesettings, loading, error };
};

export default useThemeSettings;
