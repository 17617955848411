import { useState, useEffect } from 'react';
import { getAboutkdl } from '../api/apiService';

const useAboutKdlHooks = () => {
  const [homepageblock, setHomePageBlock] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getAboutkdl();
        console.log('API Response:', data); // Debugging
        setHomePageBlock(data.homepageblock || {}); // Set homepageblock as an object
      } catch (err) {
        console.error('Error fetching homepage blocks:', err);
        setError('Failed to fetch homepage blocks');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { homepageblock, loading, error };
};

export default useAboutKdlHooks;
