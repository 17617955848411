import { useState, useEffect } from 'react';
import { getClubs } from '../api/apiService'; // Adjust path as needed

const useClubs = () => {
  const [clubs, setClubs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getClubs();
        setClubs(data || []);
      } catch (err) {
        console.error('Error fetching Clubs:', err);
        setError('Failed to fetch Clubs');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return { clubs, loading, error };
};

export default useClubs;
